import * as React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const RelatedContent = (props) => {
  return (
    <Container className="related-content section mt-2 pt-1 mb-4">
      { props.page === "blind-angels" &&
        <Row>
          <Col xs={12} className="m-auto ">
            <h2 className="title">RELATED CONTENT</h2>
          </Col>
        </Row>
      }
      <Row>
        <Col xs={12} className="m-auto">
          <Container>
            <Row className="justify-content-lg-between">

              <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/kayla">
                <h3 className="related-content-card-header">The Importance of Housing Stability for Transgender Women of Color in HIV Treatment</h3>
                <p className="related-content-card-subtext">Discover how Kayla Rena Gore's grassroots organization is advocating for transgender women of color and their need for stable housing.</p>
             
                <span className="read-more">
                  <span className="font-heavy"><span class="more-link">Read more</span></span><span class="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/dazon">
                <h3 className="related-content-card-header">Empowering Black Women <br class="desktop"/>in the American South to <br class="desktop"/>Protect Their Sexual Health</h3>
                <p className="related-content-card-subtext">Learn about the work Dázon Dixon Diallo is doing to improve access to care for women who live with and are at risk for HIV.</p>
                <span className="read-more">
                  <span className="font-heavy"><span class="more-link">Read more</span></span><span class="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/zakia">
                <h3 className="related-content-card-header">Mobile Health Units Bring <br class="desktop"/>HIV Testing to Underserved Communities in the <br class="desktop"/>American South</h3>
                <p className="related-content-card-subtext">Check out our conversation with Nationz Foundation Executive Director Zakia McKensey.</p>
                <span className="read-more">
                  <span className="font-heavy"><span class="more-link">Read more</span></span><span class="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                </span>
              </a>
              </Row>
              <Row className="justify-content-lg-between">

              <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/jose-joaquin">
                <h3 className="related-content-card-header">Overcoming Barriers <br class="desktop"/>to PrEP Medicine Use in <br class="desktop"/>Hispanic/Latino Communities <br className="desktop"/>in the American South</h3>
                <p className="related-content-card-subtext">Discover how HIV advocates José Romero and Joaquín Carcaño approach the topic of HIV prevention in the South.</p>
                <p className="related-content-card-note">PrEP=pre-exposure prophylaxis.</p>
                <span className="read-more">
                  <span className="font-heavy"><span class="more-link">Read more</span></span><span class="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/tony">
                <h3 className="related-content-card-header">Overcoming Barriers to HIV Care for Black MSM in the American South</h3>
                <p className="related-content-card-subtext">Learn how HIV advocate Tony Christon-Walker helps address barriers to care for Black MSM <br class="desktop"/>in Alabama.</p>
                <p className="related-content-card-note">MSM=men who have sex with men.</p>
                <span className="read-more">
                  <span className="font-heavy"><span class="more-link">Read more</span></span><span class="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <div className="related-content-card hide-related-content-card mb-3 mb-lg-4"></div>
             
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default RelatedContent;