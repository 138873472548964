import * as React from 'react';
import { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import RelatedContent from './related';
import HTML5Video from '../global/video.js';
import series from '../../images/Blind-angels-series-trailer-thumbnail.png';
import seriesVideo from '../../video/blind-angels_trailer.mp4';
import tony from '../../images/Blind-angels-tony-video-thumbnail.png';
import tonyVideo from '../../video/gba_ep1_compressed.mp4';
import jose from '../../images/Blind-angels-jose-joaquin-video-thumbnail.png';
import joseVideo from '../../video/gba_ep2_compressed.mp4';
import zakia from '../../images/Blind-angels-zakia-video-thumbnail.png';
import zakiaVideo from '../../video/gba_ep3_compressed.mp4';
import dazon from '../../images/Blind-angels-dazon-video-thumbnail.png';
import dazonVideo from '../../video/gba_ep4_compressed.mp4';
import kayla from '../../images/Blind-angels-kayla-video-thumbnail.png';
import kaylaVideo from '../../video/gba_ep5_compressed.mp4';
import rymsky from '../../images/Blind-angels-rymsky-temica-video-thumbnail.jpg';
import rymskyVideo from '../../video/gba_ep6_compressed.mp4';

class Content extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            VideoSrc: undefined,
            VideoThumb: undefined
        };
    }
    handleClose = () => {
        this.setState({ show: false });
    };
    handleShow = () => {
        this.setState({ show: true });
    };
    handleClick = video => {
        let VideoSrc;
        let VideoThumb;
        let VideoTrackLabel;
        //let RymskyVideo =
        //"https://player.vimeo.com/progressive_redirect/playback/652288241/rendition/720p/file.mp4?loc=external&signature=735311495fdaf53318ed7f50d2c29af65174ec26ed365ff9d4b3f2e2d5f736b3";

        switch (video) {
            case 'series':
                VideoThumb = series;
                VideoSrc = seriesVideo;
                VideoTrackLabel = 'Series Trailer';
                break;
            case 'tony':
                VideoThumb = tony;
                VideoSrc = tonyVideo;
                VideoTrackLabel = 'Birmingham, AL: Tony Christon-Walker';
                break;
            case 'jose':
                VideoThumb = jose;
                VideoSrc = joseVideo;
                VideoTrackLabel = 'Durham, NC: José Romero';
                break;
            case 'zakia':
                VideoThumb = zakia;
                VideoSrc = zakiaVideo;
                VideoTrackLabel = 'Richmond, VA: Zakia McKensey';
                break;
            case 'dazon':
                VideoThumb = dazon;
                VideoSrc = dazonVideo;
                VideoTrackLabel = 'Atlanta, GA: Dázon Dixon Diallo';
                break;
            case 'kayla':
                VideoThumb = kayla;
                VideoSrc = kaylaVideo;
                VideoTrackLabel = 'Memphis, TN: Kayla Rena Gore';
                break;
            case 'rymsky':
                VideoThumb = rymsky;
                VideoSrc = rymskyVideo;
                VideoTrackLabel = 'Memphis, TN: Kayla Rena Gore';
                break;
            default:
                break;
        }
        this.setState({
            show: true,
            VideoThumb: VideoThumb,
            VideoSrc: VideoSrc,
            VideoTrackLabel: VideoTrackLabel
        });
    };

    render() {
        return (
            <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
                <Container
                    fluid
                    className='hero blind-angels'
                    role='img'
                    aria-label='Man smelling a bundle of flowers'
                />
                <Container className='blind-angels'>
                    <Row>
                        <Col xs={10} lg={10} className='m-auto px-0'>
                            <div className='hero-callout muted-yellow-dark'>
                                <h1 className='sub-title '>Blind Angels</h1>
                                <h1 className='title '>A SERIES ON HIV IN THE AMERICAN SOUTH</h1>
                            </div>
                            <p className='header-text'>
                                A Courageous Studios production with Gilead Sciences,{' '}
                                <i>Blind Angels</i> is a new docuseries on HIV in the American
                                South. This production honors the grassroots activists and
                                organizations who are working to destigmatize and raise awareness of
                                HIV in their own communities. Each episode spotlights a different
                                activist as they work to bring HIV awareness, education, prevention,
                                and care to their communities.
                            </p>
                            <p className='header-text'>
                                The South is currently considered the epicenter of the HIV epidemic
                                in the United States, comprising just over half of new HIV diagnoses
                                in 2018. Many people in the South live in poverty and without health
                                insurance—in fact, when compared with the rest of the country, the
                                South had some of the highest rates of poverty and uninsured people,
                                according to 2017 data. Additionally, people of color and other
                                marginalized communities face even higher rates of HIV in this
                                region, with Black and Hispanic/Latino men who have sex with men
                                (MSM) and Black women bearing the brunt of the epidemic. Transgender
                                people living in the South also face disproportionately high rates
                                of new HIV diagnoses. The <i>Blind Angels</i> series explores the
                                challenges faced by some of these communities—including Alabama,
                                North Carolina, Virginia, Georgia, and <nobr>Tennessee—and</nobr>{' '}
                                shines a light on the impact of courage, family, and love.
                            </p>
                            <p className='header-text'>
                                <i>Blind Angels</i> celebrates the people who are working every day
                                in the American South to fight for access, education, and resources
                                that can help end the HIV epidemic.
                            </p>
                            <Container>
                                <Row className='videos'>
                                    <HTML5Video
                                        handleClose={this.handleClose}
                                        show={this.state.show}
                                        thumbnail={this.state.VideoThumb}
                                        url={this.state.VideoSrc}
                                        videoTrackLabel={this.state.VideoTrackLabel}
                                    />

                                    {/* Top row */}
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('series')}
                                            src={series}
                                            alt='Play the Blind Angels series trailer'
                                        />
                                        <span className='title'>Series Trailer</span>
                                        <p className='description'>
                                            <i>Blind Angels</i> is the story of advocates working
                                            within their communities across the American South to
                                            help end the HIV epidemic.
                                        </p>
                                    </Col>
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('tony')}
                                            src={tony}
                                            alt='Play Tony’s Blind Angels story'
                                        />
                                        <span className='title'>
                                            Birmingham, AL: Tony Christon-Walker
                                        </span>
                                        <p className='description'>
                                            Birmingham, AL, has a tumultuous racial history—one that
                                            continues to impact the lives of Black gay men,
                                            especially those impacted by HIV. Tony Christon-Walker,
                                            a long-term HIV survivor, has dedicated his life to
                                            helping younger generations of Black gay men access
                                            tools that can help them protect their sexual health.
                                        </p>
                                        <a className='cta-companion-article' href='tony'>
                                            <span className='font-heavy'>
                                                Read companion article
                                            </span>{' '}
                                            <i className='fa fa-chevron-right '></i>
                                        </a>
                                    </Col>

                                    {/* Bottom Row */}
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('jose')}
                                            src={jose}
                                            alt='Play Jose and Joaquin’s Blind Angels story'
                                        />
                                        <span className='title'>Durham, NC: José Romero</span>
                                        <p className='description'>
                                            As a peer navigator, José works to build trust and
                                            support within his community to help overcome
                                            challenges—such as language barriers, immigration
                                            status, and poverty—that make it harder for some
                                            Hispanics/Latinos to access healthcare. Their story is
                                            one of family and love.
                                        </p>
                                        <a className='cta-companion-article' href='jose-joaquin'>
                                            <span className='font-heavy'>
                                                Read companion article
                                            </span>{' '}
                                            <i className='fa fa-chevron-right '></i>
                                        </a>
                                    </Col>
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('zakia')}
                                            src={zakia}
                                            alt='Play Zakia’s Blind Angels story'
                                        />
                                        <span className='title'>Richmond, VA: Zakia McKensey</span>
                                        <p className='description'>
                                            A fearless activist for LGBTQIA+ and trans rights, Zakia
                                            McKensey is working to dismantle stigma and
                                            misinformation in VA. She brings HIV testing and care to
                                            local communities, offering a food pantry and other
                                            services for LGBTQIA+ people and specifically trans
                                            women of color.
                                        </p>
                                        <a className='cta-companion-article' href='zakia'>
                                            <span className='font-heavy'>
                                                Read companion article
                                            </span>{' '}
                                            <i className='fa fa-chevron-right '></i>
                                        </a>
                                    </Col>
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('dazon')}
                                            src={dazon}
                                            alt="Play Dazon's Blind Angels story"
                                        />
                                        <span className='title'>
                                            Atlanta, GA: Dázon Dixon Diallo
                                        </span>
                                        <p className='description'>
                                            Refusing to stand by, Dázon Dixon Diallo founded
                                            SisterLove, Inc., in Atlanta, GA, to provide HIV care,
                                            resources, and support to Black women. Today, she
                                            continues this work with colleagues like Antoinette
                                            Jones-Knowles, who fight isolation, myths, and ignorance
                                            with empowerment, helping Black women to break the cycle
                                            of stigma and take control of their sexual health.
                                        </p>
                                        <a className='cta-companion-article' href='dazon'>
                                            <span className='font-heavy'>
                                                Read companion article
                                            </span>{' '}
                                            <i className='fa fa-chevron-right '></i>
                                        </a>
                                    </Col>
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('kayla')}
                                            src={kayla}
                                            alt="Play Kayla's Blind Angels story"
                                        />
                                        <span className='title'>Memphis, TN: Kayla Rena Gore</span>
                                        <p className='description'>
                                            Activist Kayla Rena Gore is intimately familiar with her
                                            community’s need for stable housing. Kayla co-founded My
                                            Sistah’s House, a grassroots organization that provides
                                            multipronged resource assistance to transgender women
                                            and other LGBTQIA+ individuals of color. The
                                            organization has also broken ground on plans for
                                            permanent housing by building tiny homes for those in
                                            need.
                                        </p>
                                        <a className='cta-companion-article' href='kayla'>
                                            <span className='font-heavy'>
                                                Read companion article
                                            </span>{' '}
                                            <i className='fa fa-chevron-right '></i>
                                        </a>
                                    </Col>
                                    <Col xs={12} lg={6} className='video-card px-0'>
                                        <img
                                            onClick={() => this.handleClick('rymsky')}
                                            src={rymsky}
                                            alt='Play Rymsky and Temica’s Blind Angels story'
                                        />
                                        <span className='title'>
                                            Jackson, MS: Rymsky Evans and Temica Morton
                                        </span>
                                        <p className='description'>
                                          Jackson, MS, has one of the highest rates of HIV in America. For HIV activist Temica Morton, this means using her platform as 
                                          a nightclub owner to encourage the LGBTQIA+ community to test for HIV. For Rymsky Evans, her activism 
                                          took the form of sharing her personal HIV experience and educating other Black women 
                                          on sexual health. Even though she has passed away, Rymsky’s presence as a voice of 
                                          strength for those living with HIV will forever be remembered. Her legacy 
                                          serves as an inspiration for others working to end the HIV epidemic. 
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            <RelatedContent page='blind-angels' />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Content;
